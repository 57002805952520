import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './PersonalInformation.module.css';


function PersonalInformationModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Informações pessoais</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Preencha com suas informações pessoais:</p>
              <p><b>Nome Completo - </b> Seu nome completo (nome, sobrenome e último nome) por exemplo: Joaquim Maria Machado de Assis</p>
              <p><b>Data de Nascimento - </b> A data do seu nascimento no formato dia/mes/ano (dd/mm/aaaa) por exemplo: 20/01/1980. É importante preencher esse campo para a ferramenta calcular sua idade.</p>
              <p><b>Estado Civil - </b> Sua situação em relação ao matrimônio ou à sociedade conjugal. Por Exemplo:
                      </p>
                      <ul className={style.modalList}>
                         <li> Selecione <b>Solteiro(a)</b> se você nunca casou ou teve o casamento anulado;</li>
                         <li> Selecione <b>Casado(a)</b> se você está atualmente casado;</li>
                         <li> Selecione <b>Divorciado(a)</b> se você foi casado e já homologou divórcio pela justiça ou por uma escritura pública;</li>
                         <li> Selecione <b>Viúvo(a)</b> se você já foi casado(a) e seu cônjuge veio a falecer;</li>
                         <li> Selecione <b>Separado(a)</b> Se você é casado(a), mas seu vínculo com seu cônjuge foi dissolvido por escritura pública ou decisão judicial a sociedade conjugal;</li>
                      </ul>
                 <p></p>
              <p><b>Gênero - </b> Informe seu gênero para fins de formatação do currículo, por exemplo: para o gênero masculino o Estado Civil assumirá a desinência nominal -o (Solteiro) e para feminino -a (Solteira)</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default PersonalInformationModal;