import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Qualification.module.css';


function QualificationModal(params) {

    const [examples, setExemples] = useState(
        [
        "Boa comunicação, disponibilidade para trabalhar com o público, proativo(a), criativo(a), bom comportamento interpessoal.",
        "Conhecimento de programas do pacote office (Excel, Word, PowerPoint), utilização de gerenciadores de e-mail, digitação rápida, organização de documentos.",
        "Receptivo(a), comunicativo(a), com disponibilidade para viajar e para trabalhar aos fins de semana, boa oratória, capacidade de falar para grandes públicos.",
        "Capaz de trabalhar sob pressão, de aprender rápido, apto para trabalhar em equipe, resilente. Experiência de trabalho com vendas, telemarketing e consultoria de beleza."
    ]);

    const selectObjective = (option) => {

        let event = {
            target: {name: "qualifications", value: examples[option]}
        }    

        params.onChange(event);
        params.handleClose();
    }

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Resumo das qualificações</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p><b>Informe um resumo das suas principais qualificações, o que você está apto a fazer, que tipo de ferramenta ou método você domina etc. É recomendado que para cada vaga que você for aplicar, você adaptar essas qualificações de acordo com o perfil da empresa. Exemplos:</b></p>
                <p><span>{examples[0]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(0)}>Usar exemplo</button></p>
                <p><span>{examples[1]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(1)}>Usar exemplo</button></p>
                <p><span>{examples[2]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(2)}>Usar exemplo</button></p>
                <p><span>{examples[3]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(3)}>Usar exemplo</button></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default QualificationModal;