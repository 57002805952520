import React, { useState } from 'react';

import classNames from 'classnames';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';
import BasicTemplate from '../Templates/BasicTemplate/BasicTemplate';

import mainStyle from '../Curriculo/Curriculo.module.css';
import style from './Preview.module.css';


function Preview(params) {
    
    return (
        <>
        {params.view == "cv" &&
            <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.onChangeView}>CLIQUE AQUI PARA CONTINUAR PREENCHENDO</Badge>
         }
        <div className={style.curriculo_preview}> 
            <BasicTemplate data={params.data} />
        </div>
        </>
    );
  }
  
  export default Preview;