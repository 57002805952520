import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './OriginInfo.module.css';


function OriginInfoModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Dados de Origem</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Informações sobre os lugares que você nasceu e possuí cidadania:</p>
                <p><b>Nacionalidade - </b> Informe a sua nacionalidade, por exemplo: Brasileiro(a), Peruano(a), Argentino(a) etc.</p>
                <p><b>Naturalidade - </b> Digite a cidade (município) e o estado que você nasceu. Por exemplo: São Paulo-SP, Salvador-BA, Teófilo Otoni-MG etc.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default OriginInfoModal;