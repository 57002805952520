import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import useWindowDimensions from '../../useWindowDimensions';

import AddressModal from './AddressModal';

import mainStyle from '../Curriculo.module.css';
import style from './Address.module.css';


function Address(params) {

    const [showModal, setShowModal] = useState(false);

    const { height, width } = useWindowDimensions();

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const inputStreet = useRef(null);

    useEffect(() => {
        if(params.width >= 990)
             inputStreet.current.focus();
    }, []);

    return (
        <>
        <AddressModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Dados de Localização </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, width < 990 ? mainStyle.inputSectionOverFlow : "")}> 
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Endereço Residencial:</Form.Label>
                        <Form.Control maxLength={60} ref={inputStreet} autoComplete="nope" value={params.data.address.street} onChange={params.onChange} name="address.street" type="text" placeholder="Digite seu endereço..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>  

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Bairro:</Form.Label>
                        <Form.Control maxLength={20} autoComplete="nope" value={params.data.address.neighbor} onChange={params.onChange} name="address.neighbor" type="text" placeholder="Digite seu bairro..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Cidade:</Form.Label>
                        <Form.Control maxLength={20} autoComplete="nope" value={params.data.address.city} onChange={params.onChange} name="address.city" type="text" placeholder="Digite sua cidade..." isInvalid={false} />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>      

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Estado:</Form.Label>
                        <Form.Control name="address.state" as="select" custom value={params.data.address.state} onChange={params.onChange}>
                            <option value="">Selecione um estado</option>
                            <option value="RO">RO</option>
                            <option value="AC">AC</option>
                            <option value="AM">AM</option>
                            <option value="RR">RR</option>
                            <option value="PA">PA</option>
                            <option value="AP">AP</option>
                            <option value="TO">TO</option>
                            <option value="MA">MA</option>
                            <option value="PI">PI</option>
                            <option value="CE">CE</option>
                            <option value="RN">RN</option>
                            <option value="PB">PB</option>
                            <option value="PE">PE</option>
                            <option value="AL">AL</option>
                            <option value="SE">SE</option>
                            <option value="BA">BA</option>
                            <option value="MG">MG</option>
                            <option value="ES">ES</option>
                            <option value="RJ">RJ</option>
                            <option value="SP">SP</option>
                            <option value="PR">PR</option>
                            <option value="SC">SC</option>
                            <option value="RS">RS</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="GO">GO</option>
                            <option value="DF">DF</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>  
            </Form>
            
            <div className={mainStyle.buttonSection}> 
                    <Button onClick={params.previousStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={params.nextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>
        
        </div>
        </>
    );
  }
  
  export default Address;