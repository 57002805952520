import React, { useState, useEffect  } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import style from './Curriculo.module.css';
import CurriculoPreview from '../CurriculoPreview/CurriculoPreview';

import axios from 'axios';
import { properties } from '../../properties.js';

import useWindowDimensions from '../useWindowDimensions';


// Steps
import PersonalInformation from './PersonalInformation/PersonalInformation';
import OriginInfo from './OriginInfo/OriginInfo';
import Address from './Address/Address';
import Contact from './Contact/Contact';
import Objective from './Objective/Objective';
import Experience from './Experience/Experience';
import Education from './Education/Education';
import Qualification from './Qualification/Qualification';
import Language from './Language/Language';
import Course from './Course/Course';
import Section from './Section/Section';
import moment from 'moment';

import CurriculoDownload from '../CurriculoDownload/CurriculoDownload';
import SectionModal from './Section/SectionModal';


function Curriculo() {

    const { height, width } = useWindowDimensions();
    const [view, setView] = useState("form");
    const [downloadUrl, setDownloadUrl] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [step, setStep] = useState(0);
    const [percentCompleted, setPercenteCompleted] = useState(0);
    const [validation, setValidation] = useState({
        language: [],
        course: [],
        section: [],
        experience: [],
        education: []
    });

    const [data, setData] = useState({
        fullName: "",
        birthDate: "",
        maritalStatus: {
           maritalStatusName: ""
        },
        gender: "",
        nationality: "",
        placeOfBirth: "",
        address: {
            street: "",
            city: "",
            neighbor: "",
            state: "",
            country: "",
            postalCode: ""
        },
        contact: {
            telephoneNumber: "",
            telephoneNumberTwo: "",
            email: ""
        },
        objective: "",
        qualifications: "",
        experiences: [],
        educations: [],
        languages: [],
        courses: [],
        sections: []
    });

    const [data2, setData2] = useState({
        fullName: "Luciano Ferreira da Silva",
        birthDate: "",
        maritalStatus: {
           maritalStatusName: "Casado(a)"
        },
        gender: "M",
        nationality: "Brasileiro",
        placeOfBirth: "Paulistano",
        address: {
            street: "Rua de Matacavalos",
            city: "São Paulo",
            neighbor: "Sé",
            state: "SP",
            country: "Brazil",
            postalCode: "00000-000"
        },
        contact: {
            telephoneNumber: "(11) 0000-0000",
            telephoneNumberTwo: "(11) 0000-0000",
            email: "mail@hotmail.com"
        },
        objective: "Ser membro de uma empresa com um ambiente de trabalho dinâmico, focando na promoção do crescimento do negócio por meio de valores e serviços de qualidade.",
        qualifications: "Serviços de alta qualidade",
        experiences: [],
        educations: [{
            institutionName: "Universidade Federal do ABC",
            educationName: "Ciência e tecnologia",
            place: "Santo André",
            startDate: moment().format("YYYY-MM-DD"),
            endDate: "",
            activitiesDescription: "Diversas atividades."
         }],
        languages: [{
            language: {languageName: "Inglês"},
            level: "Básico"
        }],
        courses: [{
            year: "",
            courseName: "Excel básico",
            place: "São Paulo"
        }],
        sections: [{
            sectionName: "Palestras",
            sectionContent: "Realizei diversas palestras"
        }]
    });

    const handleChange = (e) => {

        if(e.target.name.includes(".")) {
            setData({
                ...data,
                [e.target.name.split(".")[0]]: { ...data[e.target.name.split(".")[0]], [e.target.name.split(".")[1]]: e.target.value }
            });
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            });
        }
    }

    const handleNextStep = (newValidation) => {
        setValidation({...validation, ...newValidation});
        setStep(step+1);
    }

    const handlePreviousStep = (newValidation) => {      
        setValidation({...validation, ...newValidation});
        setStep(step-1);
    }

    const handleCreateCv = () => {

        setView("download");
        setDownloadUrl("");

        if(data.gender == "") data.gender = "N";

        axios.post(properties.app_url + '/curriculo', {
            person: {
                ...data
            }
          })
          .then(function (response) {
            setDownloadUrl(response.data);
            //window.open(response.data, "_blank");
          })
          .catch(function (error) {
          });
    }

    useEffect(() => {
        setPercenteCompleted((Math.ceil(step * 100 / 11)));
      }, [step]);

    const handleChangeView = () => {
        if(view == "form") setView("cv");
        else setView("form");    
   }

   const handleChangeValidation = (newValidation) => {
     setValidation({...validation, ...newValidation});
   }

   const handleUpdateCv = () => {
       setView("form");
       setStep(0);
   }

   const handleOpenCv = () => {
       if(downloadUrl !== "") {
        window.open(downloadUrl, "_blank");
       }
   }

   const handleDownloadCv = async() => {
        if(downloadUrl !== "") {
            let blob = await fetch(downloadUrl).then(r => r.blob());
            var csvURL = window.URL.createObjectURL(blob);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'curriculo.pdf');
            tempLink.click();
        }
   }

    return (
        <div> 
            <Container className={style.top_container}>
                <div className={view != "download" ? style.cv_content_row : style.cv_content_download}>

                    {view == "download" &&
                        <div md={12} className={style.download_container}>
                              <CurriculoDownload handleUpdateCv={handleUpdateCv} handleOpenCv={handleOpenCv} handleDownloadCv={handleDownloadCv} url={downloadUrl} />
                        </div>
                    }

                    {view != "cv" && view != "download" &&
                    <div lg="4" md="12" sm="12" mt={4} className={style.main_container}>
                        {width >= 990 && view == "form" &&
                        <h3 className={style.title_page_curriculo}>Criação de Currículo</h3>
                        }

                        {width < 990  &&
                        <div md={12}  lg={12} sm={12}>
                            <CurriculoPreview data={data} percent={percentCompleted} step={step} view={view} showForm={handleChangeView} />
                        </div> 
                       }

                        {step == 0 &&
                         <PersonalInformation data={data} nextStep={handleNextStep} onChange={handleChange} showCv={handleChangeView} width={width} />
                        }

                        {step == 1 &&
                           <OriginInfo data={data} nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 2 &&
                             <Address data={data} nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 3 &&
                             <Contact data={data} nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 4 &&
                             <Objective data={data} nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 5 &&
                             <Experience data={data} validation={validation} onChangeValidation={handleChangeValidation} nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 6 &&
                             <Education data={data} validation={validation} onChangeValidation={handleChangeValidation}  nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 7 &&
                             <Qualification data={data} nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 8 &&
                            <Language data={data} validation={validation} onChangeValidation={handleChangeValidation}  nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 9 &&
                            <Course data={data} validation={validation} onChangeValidation={handleChangeValidation}  nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} showCv={handleChangeView} width={width}/>
                        }

                        {step == 10 &&
                            <Section data={data} validation={validation} onChangeValidation={handleChangeValidation}  nextStep={handleNextStep} previousStep={handlePreviousStep} onChange={handleChange} createCv={handleCreateCv} showCv={handleChangeView} width={width}/>
                        }
                    </div>
                    }

                    {/* {(width >= 990 || view == "cv") && view != "download" &&
                    } */}
                        {(width >= 990 || view == "cv") && view != "download" &&
                        <div md={8} className={view == "cv" ? style.second_container_view_cv : style.second_container}>
                            <CurriculoPreview data={data} percent={percentCompleted} step={step} view={view} showForm={handleChangeView} />
                        </div>
                        }
                </div>
            </Container>

        </div>
    );
  }
  
  export default Curriculo;