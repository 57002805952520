import React, { useEffect } from 'react';

function Adsense(props) {

    useEffect(() => {
            window.scrollTo(0, 0);
             let adsbygoogle = [];
             (adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    const loadAd = () => {

        switch(props.ad) {
        
            case "componend_ad": 
                return (
                    <ins className="adsbygoogle"
                        style={{display: "block"}}
                        data-ad-client="ca-pub-1738781469983163"
                        data-ad-slot="6819263173">
                    </ins>
            )

            case "cv_preview_ad": 
                return (
                    <ins className="adsbygoogle"
                        style={{display: "block",width:"728px",height:"80px"}}
                        data-ad-client="ca-pub-1738781469983163"
                        data-ad-slot="3138818286">
                    </ins>
            )
        }

    }

    return (
        loadAd()
    )

}


export default Adsense;