import React, { useState } from 'react';

import classNames from 'classnames';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Preview from './Preview';

import useWindowDimensions from '../useWindowDimensions';

import Adsense from '../../Adsense';

import mainStyle from '../Curriculo/Curriculo.module.css';
import style from './CurriculoPreview.module.css';


function CurriculoPreview(params) {

    const { height, width } = useWindowDimensions();

    return (
        <>
           <div className={style.progress_container}>
                <p></p>
                <h5>Progresso <span className={style.progress_step}> Etapa {params.step + 1} de 12</span></h5>
                <div className={style.progress}>
                   <ProgressBar striped  now={params.percent}  label={params.percent + "%"} />
                </div>
            </div>   

            {/* <div className={mainStyle.ad_container}>
            <p><Adsense ad="cv_preview_ad" /></p>
            </div> */}

            {(width > 990 || params.view == "cv") &&
                <Preview data={params.data} view={params.view} onChangeView={params.showForm}/>
            }
        </>
    );
  }
  
  export default CurriculoPreview;