import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Address.module.css';


function AddressModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Dados de Localização</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Informações sobre o lugar onde você mora. Importante na entrevista de emprego para a empresa calcular o tempo que você vai demorar para chegar no trabalho:</p>
                <p><b>Endereço Residencial - </b> Informe seu endereço completo: rua, número, complemento. Exemplo: Rua de matacavalos nº 1413, Apt 102 Bloco C.</p>
                <p><b>Bairro - </b> Digite seu bairro. Exemplos: Tatuapé, Santa Tereza, Penha, Brás etc.</p>
                <p><b>Cidade - </b> Digite a cidade (município) onde você mora. Exemplos: Niterói, Brumado, Piracicaba, Paraty, São Paulo etc.</p>
                <p><b>Estado - </b> Selecione o estado que você mora. Exemplos: RS, RJ, BA, PB, PI etc.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default AddressModal;