import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Language.module.css';


function LanguageModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Idiomas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Informe os idiomas que você tem conhecimento:</p>
                <p><b>Idioma - </b>Digite o nome do idioma. Exemplo: Português, Inglês, Espanhol, Italiano etc.</p>
                <p><b>Nível - </b> Selecione seu nível de proficiência no idioma. Exemplo: para o Português que é sua língua nativa selecione Fluente, para outras línguas selecione conforme seu nível de proficiência.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default LanguageModal;