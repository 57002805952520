import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Experience.module.css';


function ExperienceModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Experiência Profissional</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Informe sua experiência profissional para o entrevistador dimensionar seu histórico de experiências. Coloque a experiência mais recente (ou atual) na primeira opção e a mais antiga na última opção:</p>
                <p><b>Nome da Empresa - </b> Informe o nome da empresa.</p>
                <p><b>Data de início - </b> Data que você foi contratado pela empresa.</p>
                <p><b>Data de saída - </b> Data que você deixou a empresa. Se for seu trabalho atual selecione a opção <b>Atualmente trabalho aqui!</b></p>
                <p><b>Cargo - </b> Informe o cargo desempenhado na empresa. Se você teve vários durante sua passagem pela empresa, informe aqui o cargo de maior relevância e os demais informe no campo <b>Descrição das atividades</b></p>
                <p><b>Descrição das atividades - </b> Descreva as atividades que você desempenhou durante sua passagem na empresa, os cargos que recebestes, prêmios, batimento de metas, viagens etc.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default ExperienceModal;