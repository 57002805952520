import React, { useState, useEffect}  from 'react';

import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';

import classNames from 'classnames';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useHistory
  } from "react-router-dom";


import style from "./Header.module.css";
import styleCv from "./HeaderCv.module.css";



function Header() {
   
   let history = useHistory();

   const [pathName, setPathName] = useState(history.location.pathname);

  const [navbarExpand, setNavbarExpand] = useState(false);

  const location = useLocation();

  const onSelectOption = () => {
    setNavbarExpand(false);
  }

  const onClick = () => {
    setNavbarExpand(!navbarExpand);
  }

  useEffect(() => {
      setPathName(history.location.pathname);
  },[location]);
 
    return (
        <div classNames={pathName === "/" ? style.wrap_stricky : styleCv.wrap_stricky}>
        <Navbar expanded={navbarExpand} expand="lg" fixed={pathName === "/" ? "top" : false} className={classNames(
          pathName === "/" ? style.navbar : styleCv.navbar
          )}>
            
          <Container>
              <Link to={`/`}>
                <Navbar.Brand className="website-name">
                    {/* <img src="images/logo.jpg" width="150" heigth="100"/> */}
                    Gerar Currículo
                </Navbar.Brand>
              </Link>
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={onClick} /> */}
              {/* <Navbar.Collapse id="navbarsExample07">
                <Nav className="mr-auto" id="menu">
                <Link to={`/`}><Nav.Link  onClick={onSelectOption}>Sobre</Nav.Link></Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/teaching`}>Política de privacidade</Link></Nav.Link>
                </Nav>
              </Navbar.Collapse> */}
              </Container>
          </Navbar> 
          <div className={pathName === "/" ? style.header_padding : style.cv_header_padding  }></div>
          </div>
    )    

}

export default Header;