import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Education.module.css';


function EducationModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Formação Acadêmica</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Informe sua formação acadêmica para o entrevistador dimensionar seu histórico educacional. Coloque a formação mais recente (ou atual) na primeira opção e a mais antiga na última opção:</p>
                <p><b>Nome da Instituição de Ensino - </b> Informe o nome da Faculdade ou Escola. Exemplo: Uninove Campus Vergueiro, Etec parque da juventude etc.</p>
                <p><b>Nome do Curso - </b> Informe o título da sua formação. Exemplo: Bacharelado em Pedagogia, Ensino Médio, Pós-graduação em administração, Curso técnico em enfermagem etc.</p>
                <p><b>Local - </b> Digite o local de realização da formação. Exemplo: São Paulo, SP, Brasil. Castanhal, PA, Brasil. Fortaleza, CE, Brasil. Barcelona, Espanha. Califórnia, CA, Estados Unidos.</p>
                <p><b>Data de Início - </b> Data que você iniciou sua formação no formato dd/mm/aaaa. Por exemplo: 04/05/2014. Caso você não se lembre da data exata digite uma data aproximada.</p>
                <p><b>Data do fim/previsto da formação - </b> Selecione a data do fim da sua formação ou da previsão no formato dd/mm/aaaa. Por exemplo: 04/05/2014.</p>
                <p><b>Atividades realizadas - </b> Coloque informações relavantes da sua formação, por exemplo, trabalhos importantes, projetos, descrição de monografias, seminários, notas, destaques acadêmicos etc.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default EducationModal;