import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit'
import {plus} from 'react-icons-kit/icomoon/plus'
import {trashO} from 'react-icons-kit/fa/trashO'
import Pagination from 'react-bootstrap/Pagination';

import SectionModal from './SectionModal';


import Ad from "../../../Ad";

import mainStyle from '../Curriculo.module.css';
import style from './Section.module.css';
import { act } from 'react-dom/test-utils';


function Section(params) {
    const [items, setItems] = useState(0);
    const [active, setActive] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [validation, setValidation] = useState([]);

    const inputSectionName = useRef(null);
    const inputSectionContent = useRef(null);

    const [adNumber, setAdNumber] = useState(Math.floor(Math.random() * 5))


    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        setItems(params.data.sections.length);
        setValidation(params.validation.section);
        if(params.data.sections.length > 0)
        setActive(0);
    }, []);

    useEffect(() => {
        if(items > 0 && params.width >= 990)
         inputSectionName.current.focus();
    }, [items]);

    const addValidation = () => {
        var newValidation = {
            sectionName: false,
            sectionContent: false
         }
         let validation_e = validation;

         validation_e.push(newValidation);

         setValidation(validation_e);

         params.onChangeValidation({section: validation_e});
    }

    const addItem = () => {
      if(items < 9) {
         // setting a new experience
         var sections = params.data.sections;

         var newSection = {
            sectionName: "",
            sectionContent: ""
         }
 
         sections.push(newSection);
         addValidation();
 
         let event = {
             target: {name: "sections", value: sections}
         }
 
        params.onChange(event);

        setItems(items + 1);
        setActive(items);
      }
    }    

    const cleanValidation = (index) => {
        let arrValidation = validation;
        arrValidation[index].sectionName = false;
        arrValidation[index].sectionContent = false;
        setValidation(arrValidation);
    }
    
    const rmItem = () => {
      if(items > 0) {

        // setting a new experience
        var sections = params.data.sections;

        cleanValidation(active);
        sections.splice(active, 1);

        let event = {
            target: {name: "sections", value: sections}
        }

        params.onChange(event);

        setItems(items - 1);
        let newActive = active == 0 ? active : active - 1;
        setActive(newActive);
      }
    } 

    useEffect(() => {
    }, [active]);

    const changeActive = (index) => {
      setActive(index);
    }

    const handleErrorUpdate = (e) => {

        let arrValidation = validation;

        if(e) {
            if(e.target.name.includes(".")) {
                arrValidation[active][e.target.name.split(".")[1]] = false;
                setValidation(arrValidation);
            } else {
                arrValidation[active][e.target.name] = false;
                setValidation(arrValidation);    
            }
        }

        let event = {
            target: {name: "sections", value: params.data.sections}
        }

        params.onChange(event);
    }

    const handleSectionChange = (e) => {
        // setting a new experience
        var sections = params.data.sections;


        sections[active][e.target.name] = e.target.value;

        let event = {
            target: {name: "sections", value: sections}
        }

        handleErrorUpdate(e);

        params.onChange(event);
    }

    const renderItems = () => {
        let pagination = [];

        for(var i = 0; i < items; i++) {
          let key = i;
          pagination.push(
              <Pagination.Item 
                    key={key} 
                    onClick={() => changeActive(key)}
                    active={key == active}>
                {i + 1}
              </Pagination.Item>
          )
        }

        return (
          pagination
        )
        
    }

    const handleNextStep = () => {
        let isNotValid = false;
        let arrValidation = validation;
        let indexErrorValidation = -1;

        for(let i = 0; i < params.data.sections.length; i++) {

            if(params.data.sections[i].sectionContent == "") {
                arrValidation[i].sectionContent = true;      
                inputSectionContent.current.focus(); 
                isNotValid = true;
             }

             if(params.data.sections[i].sectionName == "") {
                arrValidation[i].sectionName = true;     
                inputSectionName.current.focus();  
                isNotValid = true;
             }

             if(isNotValid) {
                 indexErrorValidation = i;
                 break;
             }
         }

        if(isNotValid) {            
            setValidation(arrValidation);
            handleErrorUpdate();
            setActive(indexErrorValidation);
            return;
        }

        params.createCv({section: validation});
    }

    const handlePreviusStep = () => {
        params.previousStep({section: validation});
    }

    return (
        <>
        <SectionModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Outras seções: </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
                   
                   <div>                    
                    <Button disabled={items >= 10} variant="secondary" size="sm" onClick={addItem} className={style.btn_add}>
                        <Icon icon={plus} size={12} /> Adicionar
                    </Button>

                    {items > 0 &&
                    <Button disabled={items < 1} variant="danger" size="sm" onClick={rmItem}>
                        <Icon icon={trashO} size={12} /> Remover
                    </Button>
                    }
                  </div>

                  {items > 0 &&   
                    <div className={style.navigation}>
                    <Pagination size="md">                   
                        {
                            renderItems()
                        }
                    </Pagination>
                    </div>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>
                {items > 0 &&
                    <>
                        <InputGroup hasValidation>
                            <Form.Group className={mainStyle.form_group}>
                                <Form.Label className={validation[active].sectionName ? style.inputError : ""}>Nome da seção:</Form.Label>
                                <Form.Control autoComplete="nope" ref={inputSectionName} value={params.data.sections[active].sectionName} onChange={handleSectionChange} name="sectionName" type="text" placeholder="Digite o nome da seção..." isInvalid={validation[active].sectionName} />
                                <Form.Control.Feedback type="invalid">
                                    Digite o nome da seção
                                </Form.Control.Feedback>
                            </Form.Group>
                        </InputGroup>

                        <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].sectionContent ? style.inputError : ""}>Descrição da seção:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputSectionContent} value={params.data.sections[active].sectionContent} 
                                      onChange={handleSectionChange} 
                                      as="textarea" 
                                      rows={4}
                                      name="sectionContent" 
                                      type="text"  
                                      placeholder="Digite o conteúdo que aparecerá na seção" 
                                      isInvalid={validation[active].sectionContent}
                        />
                        <Form.Control.Feedback type="invalid">
                            Digite o conteúdo da seção
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 
                    </>
                }
                {items == 0 &&
                 <Ad ad_number={adNumber} />
                }  

            </Form>

            <div className={mainStyle.buttonSection}>
                    <Button onClick={handlePreviusStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Finalizar</Button>
             </div>   
        
        </div>
        </>
    );
  }
  
  export default Section;