import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Section.module.css';


function SectionModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Outras seções</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Se faltou alguma seção no seu currículo que você gostaria de inserir você pode criar utilizando esses campos:</p>
                <p><b>Nome da Seção - </b> Digite o nome que vai aparecer destacado no seu currículo. Exemplos de seções interessantes para compor o currículo são: Trabalhos voluntários, Prêmios, Intercâmbios etc.</p>
                <p><b>Descrição da seção - </b> Insira um texto para preencher o parágrafo da seção, no caso de uma seção de Trabalhos Voluntários insira um texto descrevendo essas atividades voluntárias.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default SectionModal;