import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';

import useWindowDimensions from '../../useWindowDimensions';

import PersonalInformationModal from './PersonalInformationModal';

import style from './PersonalInformation.module.css';

function PersonalInformation(params) {

    const [showModal, setShowModal] = useState(false);

    const { height, width } = useWindowDimensions();

    const [validation, setValidation] = useState({
        fullName: false,
        birthDate: false,
        gender: false
    });

    const inputFullName = useRef(null);
    const inputBirthDate = useRef(null);

    useEffect(() => {
        if(params.width >= 990)
            inputFullName.current.focus();
    }, []);


    const handleModal = () => {
        setShowModal(!showModal);
    }

    const handleShowCv = () => {
        params.showCv();
    }

    const handleNextStep = () => {

        let isNotValid = false;
        let newValidation = {};

        if(params.data.fullName == "") {
            isNotValid = true;
            newValidation.fullName = true;
            inputFullName.current.focus();
        }

        if(params.data.maritalStatus.maritalStatusName != "" && params.data.gender == "") {
            isNotValid = true;
            newValidation.gender = true;
        }

        // if(params.data.birthDate == "") {
        //     isNotValid = true;
        //     newValidation.birthDate = true;
        //     inputBirthDate.current.focus();
        // }


        if(isNotValid) {
            setValidation({...validation, ...newValidation});
            return;  
        }

        params.nextStep();

    }

    const handleInputChange = (e) => {
        setValidation({...validation, [e.target.name]: false});
        params.onChange(e);
    }

    return (
        <>
        <PersonalInformationModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Informações Pessoais </h1>
            <div>
            <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
            {params.width < 990 &&
             <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
            }
            </div>

            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, width < 990 ? mainStyle.inputSectionOverFlow : "")} >
                 <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation.fullName ? style.inputError : ""}>Nome Completo:</Form.Label>
                        <Form.Control maxLength={30} autoComplete="nope" ref={inputFullName} value={params.data.fullName} onChange={handleInputChange} name="fullName" type="text" placeholder="Digite seu nome..." isInvalid={validation.fullName}/>
                        <Form.Control.Feedback type="invalid">
                            O campo nome é obrigatório
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation.birthDate ? style.inputError : ""}>Data de Nascimento:</Form.Label>
                        <Form.Control ref={inputBirthDate} type="date" value={params.data.birthDate} onChange={handleInputChange} name="birthDate" placeholder="Digite seu nome..." isInvalid={validation.birthDate}  />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label>Estado Civil:</Form.Label>
                        <Form.Control name="maritalStatus.maritalStatusName" as="select" custom value={params.data.maritalStatus.maritalStatusName} onChange={handleInputChange} aria-label="Default select example">
                            <option value="">Selecione um estado</option>
                            <option value="Solteiro(a)">Solteiro(a)</option>
                            <option value="Casado(a)">Casado(a)</option>
                            <option value="Divorciado(a)">Divorciado(a)</option>
                            <option value="Viúvo(a)">Viúvo(a)</option>
                            <option value="Separado(a)">Separado(a)</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group_radio}>
                        <Form.Label className={validation.gender ? style.inputError : ""}>Gênero:</Form.Label>
                    </Form.Group>
                    <Form.Check
                        inline 
                        type="radio"
                        label={`Masculino`}
                        id="Masculino"
                        name="gender"
                        value="M"
                        checked={params.data.gender == "M" ? true : false}
                        onChange={handleInputChange}
                        isInvalid={validation.gender} 
                    />
                    <Form.Check
                        inline     
                        type="radio"
                        label={`Feminino`}
                        id="Feminio"
                        name="gender"
                        value="F"
                        checked={params.data.gender == "F" ? true : false}
                        onChange={handleInputChange}
                        isInvalid={validation.gender} 
                    />
                    <div className={validation.gender ? style.radioCheckError : style.displayNone}>
                            Informe um gênero
                    </div>
                </InputGroup>
            </Form>
            

            <div className={mainStyle.buttonSection}>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
            </div>  
        </div>
        </>
    );
  }
  
  export default PersonalInformation;