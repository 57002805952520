import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Objective.module.css';


function ObjectiveModal(params) {

    const [examples, setExemples] = useState(
        [
        "Buscando uma oportunidade que me permita desempenhar minhas habilidades de comunicação, organização e trabalho em grupo.",
        "Me tornar colaborador em uma empresa onde eu possa utilizar minhas habilidades e ganhar experiência enquanto contribuo para o crescimento da produtividade da empresa.",
        "Ter uma carreira desafidora em uma empresa sólida, utiilzando essa oportunidade para mostrar minhas habilidades dentro da empresa.",
        "Desempenhar atividades como confeiteiro ou padeiro.",
        "Ser membro de uma empresa com um ambiente de trabalho dinâmico, focando na promoção do crescimento do negócio por meio de valores e serviços de qualidade."
    ]);

    const selectObjective = (option) => {

        let event = {
            target: {name: "objective", value: examples[option]}
        }    

        params.onChange(event);
        params.handleClose();
    }

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Objetivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p><b>Esse campo é muito importante, informe uma frase que resuma seus objetivos de carreira ou para a vaga que você está aplicando. Tente não escrever mais do que 3 linhas. Seja breve e objetivo. Exemplos:</b></p>
                <p><span>{examples[0]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(0)}>Usar exemplo</button></p>
                <p><span>{examples[1]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(1)}>Usar exemplo</button></p>
                <p><span>{examples[2]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(2)}>Usar exemplo</button></p>
                <p><span>{examples[3]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(3)}>Usar exemplo</button></p>
                <p><span>{examples[4]}</span> <button type="button" class="btn btn-outline-success btn-sm use_objective_example" onClick={() => selectObjective(4)}>Usar exemplo</button></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default ObjectiveModal;