import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Course.module.css';


function CourseModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Cursos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Informe os cursos que você já fez. Dê preferência para os cursos mais relavantes para cada tipo de empresa e para a vaga que você está tentando uma oportunidade de emprego:</p>
                <p><b>Ano da Realização - </b> Informe o ano que você concluiu o curso. Exemplo: 2015.</p>
                <p><b>Nome do curso - </b> Digite o nome do curso. Exemplo: Excel Avançado, Informática Básica, Inglês Básico, Técnicas de recepção etc.</p>
                <p><b>Local de realização - </b> Digite em qual escola, faculdade ou empresa você fez esse curso. Exemplo: SENAI, ENAP, CNA etc.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default CourseModal;