import React from 'react';
import logo from './logo.svg';

// header
import Header from './components/Header/Header';

// home
import Home from './components/Home/Home';

// footer
import Footer from './components/Footer/Footer';

// Curriculo
import Curriculo from './components/Curriculo/Curriculo';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";


import './App.css';

function App() {
  return (
      <Router>
        <Header />

        <Switch>

          {/* home */}
          <Route exact path="/">
             <Home />
             {/* footer */}
             <Footer />
          </Route>

          <Route exact path="/criarcurriculo/">
             <Curriculo />
          </Route>

          <Route path='*' exact={true}>
            <Home />
             {/* footer */}
             <Footer />
          </Route>
          

        </Switch>     
        
      </Router>
  );
}

export default App;
