import React, { useState, useEffect}  from 'react';

import classNames from 'classnames';

import style from "./Home.module.css";
import { Container, Row, Button } from 'react-bootstrap';

import {
    Link
  } from "react-router-dom";

function Home() {
 
    return (
        <div>
            <div className={style.home_background}>
                <Container>
                    <Row>
                        <div className={style.text_center}>
                            <img width="40%" src="images/Interview-pana.svg" title="Currículo Lattes" alt="Currículo Lattes" />
                        </div>
                    </Row>
                </Container>
            </div>

            <Container>
                <h1 className={style.home_about}>
                Crie seu currículo com aparência profissional em poucos minutos e aumente suas changes de alcançar as melhores vagas no mercado trabalho
                </h1>
            </Container>

            <Container className={style.call_to_action}>
                <Link to={`/criarcurriculo`}>
                    <Button variant="primary" size="lg" className={style.call_to_action_btn}>
                        Começar Agora
                    </Button>
                </Link>
            </Container>
        </div>
    )    

}

export default Home;