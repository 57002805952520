import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import ObjectiveModal from './ObjectiveModal';

import Ad from "../../../Ad";

import mainStyle from '../Curriculo.module.css';
import style from './Objective.module.css';


function Objective(params) {

    const [showModal, setShowModal] = useState(false);

    const [adNumber, setAdNumber] = useState(Math.floor(Math.random() * 5))

    const [validation, setValidation] = useState({
        objective: false
    });

    const inputObjective = useRef(null);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        if(params.width >= 990)
            inputObjective.current.focus();
    }, []);

    const handleNextStep = () => {

        let isNotValid = false;
        let newValidation = {};

        if(params.data.objective == "") {
            isNotValid = true;
            newValidation.objective = true;
            inputObjective.current.focus();
        }

        if(isNotValid) {
            setValidation({...validation, ...newValidation});
            return;  
        }

        params.nextStep();

    }

    const handleInputChange = (e) => {
        setValidation({...validation, [e.target.name]: false});
        params.onChange(e);
    }

    const handleModalSelection = (e) => {
        setValidation({...validation, [e.target.name]: false});
        params.onChange(e);
    }

    return (
        <>
        <ObjectiveModal show={showModal} handleClose={handleModal}  onChange={handleModalSelection} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Objetivo </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>     
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation.objective ? style.inputError : ""}>Objetivo:</Form.Label>
                        <Form.Control value={params.data.objective} 
                                      ref={inputObjective}
                                      onChange={handleInputChange} 
                                      as="textarea" 
                                      rows={4}
                                      maxLength={300}
                                      name="objective" 
                                      type="text"  
                                      placeholder="Digite seus objetivos para a oportunidade..." 
                                      isInvalid={validation.objective} 
                        />
                        <Form.Control.Feedback type="invalid">
                            Digite seu objetivo. Se precisar, use um dos modelos clicando em Ajuda.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 

                <Ad ad_number={adNumber} />
            </Form>

            <div className={mainStyle.buttonSection}>  
                    <Button onClick={params.previousStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>
        
        </div>
        </>
    );
  }
  
  export default Objective;