import React, { useEffect, useState } from 'react';


import mainStyle from './Ad.module.css';

import {
    Link, useParams
  } from "react-router-dom";

function Ad(params) {

    const [link, setLink] = useState([
        "",
        "https://go.hotmart.com/F57294659G",
        "https://go.hotmart.com/L57326659I?dp=1",
        "https://go.hotmart.com/O57326810B",
        "https://go.hotmart.com/H57326892I",
        "https://go.hotmart.com/M57336296Y",
        "https://go.hotmart.com/D57337166Y?dp=1"
    ])

    return (
        <>
        <span className={mainStyle.span_ad}>#anúncio</span>
        <Link to={{pathname:link[params.ad_number + 1]}} target="_blank">
            <div className={mainStyle.ad}>
                <img src={"ads/" + (params.ad_number + 1) + "/ad_main_ligth.JPG"} />           
            </div>
         </Link>
        </>
     )

}


export default Ad;