import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit'
import {plus} from 'react-icons-kit/icomoon/plus'
import {trashO} from 'react-icons-kit/fa/trashO'
import Pagination from 'react-bootstrap/Pagination';

import Ad from "../../../Ad";


import ExperienceModal from './ExperienceModal';


import mainStyle from '../Curriculo.module.css';
import style from './Experience.module.css';


function Experience(params) {
    const [items, setItems] = useState(0);
    const [active, setActive] = useState(0);
    const [validation, setValidation] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [adNumber, setAdNumber] = useState(Math.floor(Math.random() * 5))

    const inputCompanyName = useRef(null);
    const inputStartDate = useRef(null);
    const inputEndDate = useRef(null);
    const inputRole = useRef(null);
    const inputRActivitiesDescription = useRef(null);


    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        setItems(params.data.experiences.length);
        setValidation(params.validation.experience);
        if(params.data.experiences.length > 0) {
          //setActive(0);
        }
    }, []);

    useEffect(() => {
        if(items > 0 && params.width >= 990) {
                inputCompanyName.current.focus();
        }
    },[items]);

    const addValidation = () => {
        var newValidation = {
             companyName: false,
             startDate: false,
             endDate: false,
             role: false,
             activitiesDescription: false,
             current: false
         }

         let validation_e = validation;

         validation_e.push(newValidation);

         setValidation(validation_e);

         params.onChangeValidation({experience: validation_e});
    }

    const addItem = () => {
      if(items < 9) {
         // setting a new experience
         var experiences = params.data.experiences;

         var newExperience = {
             companyName: "",
             startDate: "",
             endDate: "",
             role: "",
             activitiesDescription: "",
             current: false
         }
 
         experiences.push(newExperience);
         addValidation();
 
         let event = {
             target: {name: "experiences", value: experiences}
         }
 
        params.onChange(event);

        setItems(items + 1);
        setActive(items);

      }
    }    

    const cleanValidation = (index) => {
        let arrValidation = validation;
        arrValidation[index].companyName = false;
        arrValidation[index].startDate = false;
        arrValidation[index].endDate = false;
        arrValidation[index].role = false;
        arrValidation[index].activitiesDescription = false;
        arrValidation[index].activitiesDescription = false;
        setValidation(arrValidation);
    }
    
    const rmItem = () => {
      if(items > 0) {

        // setting a new experience
        var experiences = params.data.experiences;

        cleanValidation(active);
        experiences.splice(active, 1);

        let event = {
            target: {name: "experiences", value: experiences}
        }

        params.onChange(event);

        setItems(items - 1);
        let newActive = active == 0 ? active : active - 1;
        setActive(newActive);
      }
    } 

    useEffect(() => {
    }, [active]);

    const changeActive = (index) => {
      setActive(index);
    }

    const handleErrorUpdate = (e) => {

        let arrValidation = validation;

        if(e) {
            if(e.target.name.includes(".")) {
                arrValidation[active][e.target.name.split(".")[1]] = false;
                setValidation(arrValidation);
            } else {
                arrValidation[active][e.target.name] = false;
                setValidation(arrValidation);    
            }
        }

        let event = {
            target: {name: "experiences", value: params.data.experiences}
        }

        params.onChange(event);
    }

    const handleExperienceChange = (e) => {
        // setting a new experience
        var experiences = params.data.experiences;

        experiences[active][e.target.name] = e.target.value;

        if(e.target.type == "checkbox") {
            experiences[active][e.target.name] = e.target.checked;
            experiences[active].endDate = "";
            let newValidation = validation;
            validation[active].endDate = false;
            setValidation(...newValidation);
        }

        let event = {
            target: {name: "experiences", value: experiences}
        }

        handleErrorUpdate(e);
        params.onChange(event);
    }

    const renderItems = () => {
        let pagination = [];

        for(var i = 0; i < items; i++) {
          let key = i;
          pagination.push(
              <Pagination.Item 
                    key={key} 
                    onClick={() => changeActive(key)}
                    active={key == active}>
                {i + 1}
              </Pagination.Item>
          )
        }

        return (
          pagination
        )
        
    }

    const handleNextStep = () => {
        let isNotValid = false;
        let arrValidation = validation;
        let indexErrorValidation = -1;

        for(let i = 0; i < params.data.experiences.length; i++) {


             if(params.data.experiences[i].role == "") {
                arrValidation[i].role = true;       
                inputRole.current.focus();
                isNotValid = true;
             }

             if(params.data.experiences[i].endDate == "" && !params.data.experiences[i].current && params.data.experiences[i].startDate != "" && params.data.experiences[i].companyName != "") {
                arrValidation[i].endDate = true;   
                inputEndDate.current.focus(); 
                isNotValid = true;
             }

             if(params.data.experiences[i].startDate == "" && params.data.experiences[i].companyName != "") {
                arrValidation[i].startDate = true; 
                inputStartDate.current.focus();      
                isNotValid = true;
             }

             if(params.data.experiences[i].companyName == "") {
                arrValidation[i].companyName = true;       
                inputCompanyName.current.focus();
                isNotValid = true;
             }


             if(isNotValid) {
                 indexErrorValidation = i;
                 break;
             }
         }

        if(isNotValid) {            
            setValidation(arrValidation);
            handleErrorUpdate();
            setActive(indexErrorValidation);
            return;
        }

        params.nextStep({experience: validation});
    }

    const handlePreviusStep = () => {
        params.previousStep({experience: validation});
    }

    return (
        <>
        <ExperienceModal show={showModal} handleClose={handleModal} />
        <div className={mainStyle.component}>
            <h1 className={mainStyle.component_title}>Experiencia </h1>
            <div>
                <Badge variant="secondary" className={mainStyle.badge_info} onClick={handleModal}>AJUDA</Badge>
                {params.width < 990 &&
                <Badge variant="secondary" className={mainStyle.badge_download} onClick={params.showCv} >VER CURRÍCULO</Badge>
                }
                   
                   <div>                    
                    <Button disabled={items >= 10} variant="secondary" size="sm" onClick={addItem} className={style.btn_add}>
                        <Icon icon={plus} size={12} /> Adicionar
                    </Button>

                    {items > 0 &&
                    <Button disabled={items < 1} variant="danger" size="sm" onClick={rmItem}>
                        <Icon icon={trashO} size={12} /> Remover
                    </Button>
                    }
                  </div>

                  {items > 0 &&   
                    <div className={style.navigation}>
                    <Pagination size="md">                   
                        {
                            renderItems()
                        }
                    </Pagination>
                    </div>
                }
            </div>
            <Form onSubmit={e => { e.preventDefault(); }} className={classNames(mainStyle.inputSection, mainStyle.inputSectionOverFlow)}>   

                {items > 0 &&
                <>
                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].companyName ? style.inputError : ""}>Nome da Empresa:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputCompanyName} value={params.data.experiences[active].companyName} onChange={handleExperienceChange} name="companyName" type="text" placeholder="Digite o nome da empresa..." isInvalid={validation[active].companyName} />
                        <Form.Control.Feedback type="invalid">
                            Digite o nome da empresa
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].startDate ? style.inputError : ""}>Data de Início:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputStartDate} type="date" value={params.data.experiences[active].startDate} onChange={handleExperienceChange} name="startDate" placeholder="Informe a data de início no cargo..." isInvalid={validation[active].startDate}  />
                        <Form.Control.Feedback type="invalid">
                            Digite a data de início no cargo
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Check 
                            autoComplete="nope"
                            type="checkbox"
                            label="Atualmente trabalho aqui."
                            name="current"
                            checked={params.data.experiences[active].current}
                            onChange={handleExperienceChange}
                            //onChange={params.onChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please choose a username.
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].endDate ? style.inputError : ""}>Data de Saída:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputEndDate} disabled={params.data.experiences[active].current} type="date" value={params.data.experiences[active].endDate} onChange={handleExperienceChange} name="endDate" placeholder="Digite a data de saída..." isInvalid={validation[active].endDate}  />
                        <Form.Control.Feedback type="invalid">
                           Digite a data de saída
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].role ? style.inputError : ""}>Cargo:</Form.Label>
                        <Form.Control autoComplete="nope" ref={inputRole} value={params.data.experiences[active].role} onChange={handleExperienceChange} name="role" type="text" placeholder="Digite o cargo ocupado" isInvalid={validation[active].role} />
                        <Form.Control.Feedback type="invalid">
                            Digite o cargo
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Group className={mainStyle.form_group}>
                        <Form.Label className={validation[active].activitiesDescription ? style.inputError : ""}>Descrição das atividades:</Form.Label>
                        <Form.Control autoComplete="nope" value={params.data.experiences[active].activitiesDescription} 
                                      onChange={handleExperienceChange} 
                                      as="textarea" 
                                      rows={4}
                                      name="activitiesDescription" 
                                      type="text"  
                                      placeholder="Digite as atividades realizadas na empresa..." 
                                      isInvalid={validation[active].activitiesDescription} 
                        />
                        <Form.Control.Feedback type="invalid">
                            Digite as principais atividades realizadas
                        </Form.Control.Feedback>
                    </Form.Group>
                </InputGroup> 
                </>
                }
                {items == 0 &&
                 <Ad ad_number={adNumber} />
                } 

            </Form>
            <div className={mainStyle.buttonSection}>
                    <Button onClick={handlePreviusStep} className={classNames(mainStyle.btn_control, "float-left", mainStyle.btn_info)} variant="dark">Anterior</Button>
                    <Button onClick={handleNextStep} className={classNames(mainStyle.btn_control, "float-right")} variant="dark">Próximo</Button>
                </div>
        
        </div>
        </>
    );
  }
  
  export default Experience;