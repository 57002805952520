import React, { useState } from 'react';

import classNames from 'classnames';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';


import moment from 'moment';
import ChangeHighlight from "react-change-highlight";

import mainStyle from '../../Curriculo/Curriculo.module.css';
import style from './BasicTemplate.module.css';


function BasicTemplate(params) {    

    var a = moment();
    var b = moment(params.data.birthDate);
    var years = isNaN(a.diff(b, 'years')) ? "" : a.diff(b, 'years') + " anos";

    const name = React.createRef();
    const second_infomation = React.createRef();

    return (
        <>
        <div className={style.header_top}>
        <ChangeHighlight highlightClassName={style.highlight} hideAfter={600} ssr={true}>
        <div>
            
              <h1 className={style.nome_completo} ref={name}>
                  {params.data.fullName}
              </h1>
        </div>
        </ChangeHighlight>

        <ChangeHighlight highlightClassName={style.highlight} hideAfter={600} ssr={true}>
        <div className={style.second_infomation}>
            <p>
               <span ref={React.createRef()}>
                {years}
                </span>
                
                {((params.data.birthDate && params.data.maritalStatus.maritalStatusName) ||
                  (params.data.birthDate && params.data.nationality) ||
                  (params.data.birthDate && params.data.placeOfBirth))
                && <>&nbsp;-&nbsp;</>}


                <span ref={React.createRef()}>
                {params.data.maritalStatus.maritalStatusName.substring(0, params.data.maritalStatus.maritalStatusName.length - 4)}
                </span>

                {(params.data.gender != "" &&  params.data.maritalStatus.maritalStatusName !== "") ? params.data.gender == "M" ? "o" : "a" : ""}


                {((params.data.maritalStatus.maritalStatusName && params.data.nationality) ||
                  (params.data.maritalStatus.maritalStatusName && params.data.placeOfBirth))
                && <>&nbsp;-&nbsp;</>}   
                
                <span ref={React.createRef()}>
                  {params.data.nationality}
                </span>  

                {(params.data.nationality && params.data.placeOfBirth)
                && <>&nbsp;-&nbsp;</>}

                <span ref={React.createRef()}>
                  {params.data.placeOfBirth}
                </span>
                
            </p>
              
          </div>
          
          </ChangeHighlight>
          
          <ChangeHighlight highlightClassName={style.highlight} hideAfter={600} ssr={true}>
          <div className={style.address}>
                  <p>

                    <span ref={React.createRef()}>{params.data.address.street}</span>

                    {((params.data.address.street && params.data.address.neighbor) ||
                    (params.data.address.street && params.data.address.city) ||
                    (params.data.address.street && params.data.address.state))
                    && <>&nbsp;-&nbsp;</>}
                   
                    <span ref={React.createRef()}>
                    {params.data.address.neighbor}
                    </span>

                    {((params.data.address.neighbor && params.data.address.city) ||
                    (params.data.address.neighbor && params.data.address.state))
                    && <>&nbsp;-&nbsp;</>}

                    <span ref={React.createRef()}>
                    {params.data.address.city}
                    </span>

                    {(params.data.address.city && params.data.address.state)
                    && <>&nbsp;-&nbsp;</>}
                    <span ref={React.createRef()}>
                    {params.data.address.state}
                    </span>
                  </p>
          </div>
          </ChangeHighlight>

          <ChangeHighlight highlightClassName={style.highlight} hideAfter={600} ssr={true}>
          <div className={style.contact_details}>
                <p>
                  
                  {params.data.contact.telephoneNumber && 
                  <>Tel: </>
                  }

                  <span ref={React.createRef()}> 
                  {params.data.contact.telephoneNumber}
                  </span>

                  {params.data.contact.telephoneNumber && params.data.contact.telephoneNumberTwo && 
                     <>&nbsp;/&nbsp;</>
                  }

                  {params.data.contact.telephoneNumberTwo && 
                  <>Cel: </>
                  }

                  <span ref={React.createRef()}>  
                  {params.data.contact.telephoneNumberTwo}
                  </span>

                  {(params.data.contact.telephoneNumber || params.data.contact.telephoneNumberTwo) && params.data.contact.email &&
                     <>&nbsp;-&nbsp;</>
                  }

                  {params.data.contact.email && <>E-mail: </>}
                  <span ref={React.createRef()}> 
                  {params.data.contact.email}
                  </span>

                </p>      


          </div>
          </ChangeHighlight>
          </div>
     
            <div className={style.objectives}>
              {params.data.objective && 
              <h1>OBJETIVO:</h1>
              }
              <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>  
              <p>
                <span ref={React.createRef()}>{params.data.objective}</span>
              </p>
              </ChangeHighlight>  
            </div>

            {params.data.objective && params.data.experiences.length > 0 &&
              <div className={style.section_division}><hr /></div>
            }

            <div className={style.cv_section}>
               {params.data.experiences.length > 0 &&
               <h1>EXPERIÊNCIA PROFISSIONAL:</h1>
               }

                {params.data.experiences.map((ex, index) => (
                  <>
                      <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
                      <p className={style.experience_main_information}>
                        {ex.companyName && <b>Empresa:&nbsp;</b> }
                        <span ref={React.createRef()}>{ex.companyName}</span>

                        {ex.role && ex.companyName && <>&nbsp;/&nbsp;</>}
                        {ex.role && <b>Cargo:&nbsp;</b> }
                        {ex.role}
                      </p>
                      </ChangeHighlight>

                      <p className={style.experience_period}>
                        {ex.startDate && <b>Período:&nbsp;</b>}
                        {<>{ex.startDate}</> }

                        {ex.current && ex.startDate && <>&nbsp;-&nbsp;Atualmente</>}

                        {ex.startDate && ex.endDate && <>&nbsp;Até&nbsp;</>}

                        {<>{ex.endDate}</> }
                      </p>

                      <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
                      <p className={style.descricao_atividades}>
                          <span ref={React.createRef()}>{ex.activitiesDescription}</span>
                      </p>
                      </ChangeHighlight>
                  </>
                ))}
            </div>

            {params.data.objective && params.data.educations.length > 0 &&
              <div className={style.section_division}><hr /></div>
            }

            <div className={style.cv_section}>
               {params.data.educations.length > 0 &&
               <h1>FORMAÇÃO ACADÊMICA:</h1>
               }

                {params.data.educations.map((fo, index) => (
                  <>
                      <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
                      <p className={style.experience_main_information}>
                        {fo.institutionName && <b>Instituição:&nbsp;</b> }
                        <span ref={React.createRef()}>{fo.institutionName}</span>

                        {fo.educationName && fo.institutionName && <>&nbsp;/&nbsp;</>}
                        {fo.educationName && <b>Curso:&nbsp;</b> }
                        <span ref={React.createRef()}>{fo.educationName}</span>
                        {fo.educationName && <>.&nbsp;</> }

                        <span ref={React.createRef()}>{fo.place}</span>

                      </p>

                      <p className={style.experience_period}>
                        {fo.data_inicio && <b>Período:&nbsp;</b>}
                        {<>{fo.startDate}</> }

                        {fo.startDate && fo.endDate && <>&nbsp;Até&nbsp;</>}

                        {<>{fo.endDate}</> }
                      </p>
                      </ChangeHighlight>

                      <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
                      <p className={style.descricao_atividades}>
                          <span ref={React.createRef()}>{fo.activitiesDescription}</span>
                      </p>
                      </ChangeHighlight>
                  </>
                ))}


            </div>

            {params.data.objective && params.data.qualifications &&
              <div className={style.section_division}><hr /></div>
            }

            <div className={style.cv_section}>
               {params.data.qualifications  &&
               <h1>RESUMO DAS QUALIFICAÇÕES:</h1>
               }

              <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
                <p className={style.qualificacoes}>
                    <span ref={React.createRef()}>{params.data.qualifications}</span>
                </p>
              </ChangeHighlight>
            </div>

            
            {params.data.objective && params.data.languages.length > 0 &&
              <div className={style.section_division}><hr /></div>
            }

            <div className={style.cv_section}>
               {params.data.languages.length > 0  &&
               <h1>IDIOMAS:</h1>
               }

              <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
              <p>
              {params.data.languages.map((lan, index) => (   
                  <>               
                  <span><b ref={React.createRef()}>{lan.language.languageName}</b></span>
                  {lan.level && lan.language.languageName && <>:</>}
                  <span ref={React.createRef()}>&nbsp;{lan.level}</span>
                  {lan.level && <>.&nbsp;</>}                  
                  </>
                ))}
              </p>
              </ChangeHighlight>  
              
            </div>

            {params.data.objective && params.data.courses.length > 0 &&
              <div className={style.section_division}><hr /></div>
            }

            <div className={style.cv_section}>
               {params.data.courses.length > 0  &&
               <h1>CURSOS:</h1>
               }

              <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
              
              {params.data.courses.map((course, index) => (   
                <p>
                  <>    
                  <span ref={React.createRef()}>{course.year}</span>
                  {course.year && course.courseName && <>&nbsp;-&nbsp;</>}

                  <span ref={React.createRef()}>{course.courseName}</span>

                  {course.courseName && course.place && <b>&nbsp;(</b>}
                  <b ref={React.createRef()}>{course.place}</b>
                  {course.courseName && course.place && <b>)&nbsp;</b>}               
                  </>
                </p>
                ))}
              </ChangeHighlight>  
              
            </div>

            {params.data.sections.map((section, index) => ( 

              <>
              {params.data.objective && params.data.sections.length > 0 &&
                <div className={style.section_division}><hr /></div>
              }  

              <div className={style.cv_section}>
                 <ChangeHighlight highlightClassName={style.highlight} hideAfter={600}>
                 <h1><span  ref={React.createRef()}>{section.sectionName.toUpperCase()}</span></h1>
                 <p>
                  <span ref={React.createRef()}>{section.sectionContent}</span>
                 </p>
                 </ChangeHighlight>
               </div>
               </>
            ))}

          

            

         </>
    );
  }
  
  export default BasicTemplate;