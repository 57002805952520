import React, { useState } from 'react';

import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import mainStyle from '../Curriculo.module.css';
import style from './Contact.module.css';


function ContactModal(params) {

    return ( 
        <Modal show={params.show} onHide={params.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Dados de Contato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Informações de contato. Essas informações são importantes para que o entrevistador possa entrar em contato com você para lhe chamar para uma entrevista ou para começar a trabalhar:</p>
                <p><b>Telefone - </b> Seu telefone residêncial. Caso não tenha informe o número do seu celular.</p>
                <p><b>Celular - </b> Número do seu celular. Atualmente é muito comum a utilização do primeiro contato via rede social (whatsapp), por isso coloque o número do seu celular, se você tiver dois números, coloque o número principal. </p>
                <p><b>E-mail - </b> Digite seu e-mail, Exemplo: "contato@gerarcurriculo.com". Muitas empresas utilizam esse meio para entrar em contato com os candidatos às vagas de emprego.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={params.handleClose}>
                 Fechar
                </Button>
            </Modal.Footer>
          </Modal>
    );
  }
  
  export default ContactModal;