import React, { useState } from 'react';

import classNames from 'classnames';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


import useWindowDimensions from '../useWindowDimensions';

import Adsense from '../../Adsense';
import Ad from '../../Ad';


import mainStyle from '../Curriculo/Curriculo.module.css';
import style from './CurriculoDownload.module.css';


function CurriculoDownload(params) {

    const { height, width } = useWindowDimensions();

    return (
        <div className={style.curriculo_download}>
           <h1 className={style.component_title}>Download Curriculo </h1>

           {params.url == "" && 
                <p>Gerando currículo... <Spinner animation="border" style={{marginRight: "10px"}}/></p>
           }

            {params.url != "" && 
              <p>Parabéns, seu currículo foi criado com sucesso. Clique em abrir currículo para visualizar o arquivo.</p>
            } 

           {width <= 996 &&
           <Row className={style.buttons_row}>
               <Col lg="12">
                    {/* <Button variant="success" onClick={params.handleDownloadCv}>Baixar Currículo</Button> */}
                    {params.url == "" && <Spinner animation="border" style={{marginRight: "10px"}}/>}
                    {params.url !== "" &&
                        <Button variant="secondary" onClick={params.handleOpenCv}>Abrir currículo</Button>
                    }
                    <Button variant="warning" onClick={params.handleUpdateCv}>Editar currículo</Button>
                </Col>
           </Row>
          }
           
           <Row className={style.ad_row}>
                
               <Col  lg="3" md="4" sm="6" xs={12}>
                     <Ad ad_number={Math.floor(Math.random() * 5)} />
               </Col>
                {width > 990 &&
                    <Col  lg="3" md="4" sm="6" xs={12}>
                            <Adsense ad="componend_ad" />
                    </Col>
                }
                 {width > 990 &&
                    <Col  lg="3" md="4" sm="6" xs={12}>
                            <Ad ad_number={Math.floor(Math.random() * 5)} />
                    </Col>
                }
           </Row>

          {width > 996 &&
           <Row className={style.buttons_row}>
               <Col lg="12">
                    {/* <Button variant="success" onClick={params.handleDownloadCv}>Baixar Currículo</Button> */}
                    {params.url == "" && <Spinner animation="border" style={{marginRight: "10px"}}/>}
                    {params.url !== "" &&
                        <Button variant="secondary" onClick={params.handleOpenCv}>Abrir currículo</Button>
                    }
                    <Button variant="warning" onClick={params.handleUpdateCv}>Editar currículo</Button>
                </Col>
           </Row>
          }


        </div>
    );
  }
  
  export default CurriculoDownload;